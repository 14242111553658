import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  SubTitle,
  Input,
  Button,
  Field,
  Control,
  Columns,
  Column,
  StaticButton,
  MessageParagraph,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Density Calculator",
    link: "/density-calculator/",
  },
]
const seeAlsoArray = [
  "/density-and-mass-capacity-unit-converter",
  "/mass-and-weight-units-converter",
  "/volume-units-converter",
  "/specific-heat-calculator",
  "/boyles-law-calculator",
  "/charles-law-calculator",
  "/ohms-law-calculator",
]

function DensityCalculator(props) {
  let [ErrorMessage, setErrorMessage] = useState("")
  let mass = useRef("")
  let volume = useRef("")
  let density = useRef("")

  // var mydiv = document.getElementById('myerror');
  var errors = function (mass, volume, density) {
    if (mass === "" && volume === "" && density === "") {
      setErrorMessage(
        "All three of your inputs are blank! We need two inputs to find the third."
      )
    } else if (mass !== "" && volume !== "" && density !== "") {
      setErrorMessage(
        "All three of your inputs are filled! We only need two inputs to find the third."
      )
    } else if (mass === "" && volume === "") {
      setErrorMessage(
        "Both your mass input and your volume input are blank! We need two inputs to find the third."
      )
    } else if (mass === "" && density === "") {
      setErrorMessage(
        "Both your mass input and your density input are blank! We need two inputs to find the third."
      )
    } else if (volume === "" && density === "") {
      setErrorMessage(
        "Both your volume input and your density input are blank! We need two inputs to find the third."
      )
    } else {
      return true
    }
  }

  var mvd = function (mass, volume, density) {
    if (mass === "") {
      return "mass"
    } else if (volume === "") {
      return "volume"
    } else if (density === "") {
      return "density"
    }
  }
  var reset = function () {
    mass.current.value = ""
    volume.current.value = ""
    density.current.value = ""
    setErrorMessage("")
  }
  function calculate() {
    let Xmass = mass.current.value
    let Xvolume = volume.current.value
    let Xdensity = density.current.value

    let solving = mvd(Xmass, Xvolume, Xdensity)

    if (errors(Xmass, Xvolume, Xdensity) === true) {
      if (solving === "mass") {
        mass.current.value = Number(Xdensity * Xvolume)
      } else if (solving === "volume") {
        volume.current.value = Number(Xdensity * Xmass)
      } else if (solving === "density") {
        density.current.value = Number(Xmass / Xvolume)
      }
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Density Calculator - Simple Calculate Density"
        description="Density Calculator, Simple calculate Density, Calculate Density, mass, or volume. Enter two inputs to find the third step by step."
        keywords={[
          "Density, Density calculator, calculate Density,Calculate Density mass or volume, Enter two inputs to find the third density.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Density Calculator</Title>
        <SubTitle>Density</SubTitle>

        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>Mass</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input radius="0" type="number" ref={mass} />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  Kg
                </StaticButton>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Volume</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input radius="0" type="number" ref={volume} />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  m<sup>3</sup>
                </StaticButton>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Density</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input radius="0" type="number" ref={density} />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  kg/m<sup>3</sup>
                </StaticButton>
              </Control>
            </Field>

            <FlexDiv display="flex">
              <Button
                borderColor="#1da1f2"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                value="Calculate"
                onClick={calculate}
              >
                Calculate
              </Button>
              <Button
                borderColor="#ea4336"
                color="#ea4336"
                hoverBorderColor="#ea4336"
                hoverBackgroundColor="#ea4336"
                hoverColor="white"
                value="Reset"
                onClick={reset}
              >
                Reset
              </Button>
            </FlexDiv>
            <MessageParagraph color="red">{ErrorMessage}</MessageParagraph>
          </Column>
          <Column>
            <FlexDiv maxWidth="350px" margin="auto">
              <SmallImg filename="density.png" alt="density calculator" />
            </FlexDiv>
          </Column>
        </Columns>
        <br />

        <h3>Density</h3>
        <p>
          Density is a measurement that relates the quantity of matter an object
          has to its volume.
        </p>
        <br />
        <h3>Density Formula</h3>
        <p>
          Density is resolved by dividing the mass of an object by its volume.
          <br />
          <strong>density = mass (of the object) / volume</strong>
          <br />
          Calculate Density by entering two inputs to find the third.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default DensityCalculator
